import React from 'react';

import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom'; 

function Home() {
  return (
    <div>


      Welcome to the Home Page!
    </div>
  );
}
 
export default Home;