import React, { Component } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { BrowserRouter, Switch, Route, NavLink, Redirect  } from 'react-router-dom';
import './Certificates.scss';
// import MemberRecordsService from './MemberRecordsService';
import GlobalService from '../../Utils/GlobalService';
// import { ProfileManagement } from './Tabs/ProfileManagement';
// import { DocumentManagement } from './Tabs/DocumentManagement';
// import { CPDManagement } from './Tabs/CPDManagement';
import PaymentHistoryService from '../MemberRecords/PaymentHistoryService';
import { CGSCertificates } from '../MemberRecords/Tabs/ProfileManagementComponents/CGSCertificates';
import { EventCertificates } from '../MemberRecords/Tabs/ProfileManagementComponents/EventCertificates';

// import { PaymentHistory } from '../MemberRecords/Tabs/ProfileManagementComponents/PaymentHistory';
// import { UserLodgement } from '../MemberRecords/Tabs/ProfileManagementComponents/UserLodgement';
// UserLodgement







export class Certificates extends Component {
    

    constructor(props) {
        super(props);
        this.activeIndex = 1;
        this.globalservice = GlobalService;
        this.paymentservice = PaymentHistoryService;

        if(window.location.pathname=='/certificates/events'){
            this.paymentservice.setTab(1);
        }
        // alert(window.location.pathname);
    }
    activeIndex = 0

    template(options){

    }

    changeRoute = (link) =>{
        // alert(link)
        this.props.history.push({
            pathname: link,
            state: {}
        });
    }


    setActiveIndex(index){
        this.paymentservice.setTab(index)
        // alert(index);
        // this.MemberRecordsService.test();
    }


    items = [
        {
            label:'Certificate of Good Standing',
            icon:'pi pi-fw pi-users',
            command:()=>{
                this.changeRoute('/certificates/cgs')
            }
        },
        {
            label:'Event Certificate',
            icon:'pi pi-fw pi-book',
            command:()=>{
                this.changeRoute('/certificates/events')
            }
        }
    ];
  
    render() {
        // const scrollableTabs = Array.from({ length: 50 }, (_, i) => ({ title: `Tab ${i + 1}`, content: `Tab ${i + 1} Content` }))

        return (
            <div className="tabview-demo">
                 <TabMenu model={this.items} activeIndex={this.paymentservice.getTab()} onTabChange={(e) => this.setActiveIndex(e.index)}/>

                <div className="inner-content pt-3 mt-3">
                    {(this.paymentservice.getTab()==0) && <CGSCertificates userData={{user_id:null}}  />}
                    {(this.paymentservice.getTab()==1) && <EventCertificates userData={{user_id:null}}  />}
                </div>
     
            </div>
        )
    }
}