import React, { Component } from 'react';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PaymentHistoryService from '../../PaymentHistoryService';
import { Image } from 'primereact/image';
import moment from 'moment';

import { apiUrl, serverUrl, approval_status } from '../../../../Utils/Common';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

import debounce from 'lodash.debounce';


export class UserLodgement extends Component {
    
    paymentservice = null;
    loader = false;
    constructor(props){
        super(props);
        
        this.paymentservice = PaymentHistoryService;
        // console.log(props);
        this.state = {
            loader:false,
            form:this.props.userData,
            list:this.props.paymentList,
            payment_loader:this.props.payment_loader,
            totalRecords: 0,
            lazyParams: {
                search_text:'',
                first: 0,
                rows: 50,
                page: 1,
                sortField: null,
                sortOrder: -1,
                filters: {
                    'name': { value: '', matchMode: 'contains' },
                    'country.name': { value: '', matchMode: 'contains' },
                    'company': { value: '', matchMode: 'contains' },
                    'representative.name': { value: '', matchMode: 'contains' },
                }
            }

        }

        // alert(this.state.payment_loader);
        this.products = [];
        
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.setForm = this.setForm.bind(this);
        this.callPayments = this.callPayments.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.searchText = this.searchText.bind(this);
        this.handleResize();
        this.onChangeDebounced = debounce(this.onChangeDebounced, 1000)
        window.addEventListener('resize', this.handleResize)
       
        
        // if(!this.loader){
            // alert(); 
            this.callPayments();
        // }
        // alert();
        // console.log('test');
        // this.setForm(this.props.userData)
    }

    onStatusChange(e, rowdata, col){
        // console.log(e,rowdata, col);
        rowdata.for_approval = e.value;

        let new_data = {reference: rowdata.reference, for_approval: e.value};
        let ins = this;
        this.paymentservice.updateMemberPayment(new_data).then(function (response) {
            // console.log(response);
            let data = response.data.data;
            ins.state.list[col.rowIndex].for_approval = e.value;
            ins.setState({list: ins.state.list});
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success', detail:'Successfully updated.', life: 3000});
    }


    callPayments(){

        let ins = this;

        this.state.loader = true;
            this.paymentservice.getMemberCGS(this.state.form.user_id, this.state.lazyParams).then(function (response) {
                // console.log(response);
                let data = response.data.data.payment;
                let total = response.data.data.total;
                // console.log(data);
                setTimeout(()=>{
                    ins.setState({list: data});
                    ins.setState({totalRecords: total});
                    ins.setState( {loader: false});
                },500);
              })
              .catch(function (error) {
                console.log(error);
              });
        
       

    }
    
    handleResize() {
        this.table_size = window.innerHeight-290;
    }

    downloadPDFlink(data){



        // const link = document.createElement("a");
        // link.target = "_blank";
        // link.href = `${serverUrl}${data.pdf_link}`;
        // link.download = 'CGS-'+data.payment_year+'.pdf';
        // link.click();

        fetch(`${serverUrl}${data.pdf_link}`)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download =  'CGS-'+data.payment_year+'.pdf';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        //   alert('your file has downloaded!');
         // or you know, something with better UX...
        })
        .catch(() => alert('Error!'));

    }

    onSort(event){
        this.setState({ lazyParams: event }, this.callPayments);
    }


    onPage(event) {

        this.state.lazyParams.first = event.first;
        this.state.lazyParams.rows = event.rows;
        this.state.lazyParams.page = event.page + 1;
        this.setState({
                
                lazyParams: this.state.lazyParams
            });
            this.callPayments();
    }


    setForm(event){
        this.setState({ form: event });
    } 
    ReferenceBody = (rowData, column) => {
        return <span className="nowrap">{rowData.reference} </span>;
    }
    ValidDate = (rowData, column) => {
        return <span>{moment(rowData[column.field]).format('MMM DD, YYYY')} </span>;
    }    
    
    downloadPDF = (rowData, column) => {
        if(rowData[column.field]==''){
            return <span><i className="fa fa-file-pdf-o disabled" ></i> </span>;            
        }
        return <span><i className="fa fa-file-pdf-o" onClick={() => this.downloadPDFlink(rowData)}></i> </span>;
    }

    approvalColumn = (rowData, col) => {
        return   <Dropdown disabled={(rowData.for_approval==0)} className="p-inputtext-sm block mb-1 col-12 p-0" value={rowData.for_approval}  onChange={(e) => this.onStatusChange(e, rowData, col)}  options={approval_status} optionLabel="status" optionValue="value" placeholder="Select Action" />
    }

    depositSlip = (rowData) => {
        if(rowData.payment_picture==''){
            // https://cdn.vectorstock.com/i/1000x1000/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.webp
            return <Image lt="Image" width="50" preview  src="https://cdn.vectorstock.com/i/1000x1000/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.webp" alt="Image Text" />;
        }
        return <Image lt="Image" width="50" preview  src={`${serverUrl}${rowData.payment_picture}`} alt="Image Text" />;
}
searchText = (data) => {
    this.state.lazyParams['search_text'] = data.target.value;
    this.setState(this.state)
    this.onChangeDebounced(data);
}

onChangeDebounced = (e) => {
    // Delayed logic goes here
    this.callPayments();
  }

    
    payStatus = (rowData) => {
        let val = rowData.payment_status;
        if(val==1){
            return <span className="paid">Paid </span>;
        }
        if(val==0){
            return <span className="unpaid">Unpaid </span>;
        }
        
    }
    
    render() {

        const template = {
            layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            },
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    </React.Fragment>
                );
            },
        };
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.callPayments} />;
        const paginatorRight = <Button type="button" icon="pi pi-plus" className="p-button-text" />;

        const loader = <div className="dialog-loader"><i className="fa fa-spin fa-spinner"></i> Loading...</div>;

        return (
            <div>

            <Toast ref={(el) => this.toast = el} />

            <div className="head-label">
                <h5 className="float-left"> <i className="pi pi-fw pi-users"></i> Profile Management</h5>


                <InputText type="text" className="search_text" placeholder="Search here..."  value={this.state.lazyParams.search_text} onChange={(e) => this.searchText(e)}/>
                
            </div>

            <div className="card">

                <DataTable 
                size="small" 
                lazy 
                responsiveLayout="scroll" 
                totalRecords={this.state.totalRecords}
                onPage={this.onPage}
                onSort={this.onSort} 
                paginator 
                first={this.state.lazyParams.first} 
                rows={this.state.lazyParams.rows} 
                paginatorTemplate={template}
                paginatorLeft={paginatorLeft} 
                paginatorRight={paginatorRight}
                scrollable 
                scrollHeight={this.table_size}
                paginatorClassName="justify-content-end" className="mt-6"
                sortField={this.state.lazyParams.sortField} 
                sortOrder={this.state.lazyParams.sortOrder}
                showGridlines

                loading={this.state.loader}
                value={this.state.list} 
                responsiveLayout="scroll" className="payment_history_table">
                    

                    <Column sortable field="full_name" header="Name" style={{display: this.state.form.user_id ? 'none' : ' ' }}></Column>
                    <Column sortable field="reference" header="Business Form" body={this.ReferenceBody} ></Column>
                    <Column sortable field="transaction_code" header="Transaction Code"></Column>
                    <Column sortable field="payment_year" header="Transaction Year"></Column>
                    <Column sortable field="created_date" header="Receipt Date" body={this.ValidDate}></Column>
                    <Column sortable field="payment_status" header="Payment Status" body={this.payStatus}></Column>
                    {/* <Column sortable field="for_approval" header="Approval" body={this.approvalColumn}></Column> */}
                    <Column sortable field="total" header="Total Amount"></Column>
                    <Column sortable field="pdf_link" className="download-head" header="Download" body={this.downloadPDF}></Column>

    
                </DataTable>
            </div>
            </div>

        );

    }
}