
import {axiosInstance} from '../../Auth/interceptor';
import axios from 'axios'
import { apiUrl } from '../../Utils/Common';
class PaymentHistoryService {
    static classInstance = null;
    static getInstance() {
        if (PaymentHistoryService.classInstance === null) {
            PaymentHistoryService.classInstance = new PaymentHistoryService();
        }
        return this.classInstance;
    }

    constructor() {
    
    }
    default_tab = 0;

    setTab(tab){
        this.default_tab = tab;
    }
    getTab(){
       return this.default_tab;
    }

    

    getMemberPayment(id, filters={}){

        return axios.post('Payment/getList/',{id:id, filters: filters})
    }  

    getMemberCGS(id, filters={}){

        return axios.post('Payment/getListCGS/',{id:id, filters: filters})
    }   
    getMemberEvents(id, filters={}){

        return axios.post('Events/getCertificates/',{id:id, filters: filters})
    }   
    getInfoPages(){

        return axios.get('Config/info_page_all/')
    } 
    setInfoPageActive(id){

        return axios.post('Config/info_page_active/',{id:id})
    }    
    updateMemberPayment(data){

        return axios.post('Payment/update_payment_member/',data)
    }

}
export default PaymentHistoryService.getInstance();