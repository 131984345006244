import React, { Component } from 'react';

import { InputText } from 'primereact/inputtext';


import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Avatar } from 'primereact/avatar';

import { Divider } from 'primereact/divider';

import { serverUrl, user_status } from '../../../../Utils/Common';
import { Dropdown } from 'primereact/dropdown';


export class UserDetails extends Component {
    constructor(props){
        super(props);
        console.log(props.userData);
        let form = JSON.parse(JSON.stringify(this.props.userData));
        if(form.expiration){
            form.expiration = new Date(form.expiration);
        }
        this.state = {
            form: form
        }
        
        this.setForm = this.setForm.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

    

        // this.setForm(this.props.userData)
    }

    onStatusChange(e) {
        console.log(e.value);
        this.state.form['active'] = e.value;
        this.setState(this.state);
        this.props.changeData(this.state.form);
    }


    setForm(event){
        this.setState({ form: event });
    }

    changeValue(name, event){
        this.state.form[name] = event.target.value;
        this.setState(this.state)
        this.props.changeData(this.state.form);
    }

    test(){
        alert();
    }

    
    memberservice = null;
    _Mounted = false;



    render() {
        return (

                
            <div>
                <div className="row">

                    <div className="mb-2 col-12 row">
                        {/* <div className="ml-3 col-3 row">
                            <Avatar label={this.state.form.first_name.charAt(0)+this.state.form.last_name.charAt(0)} className="mr-2" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} size="xlarge"  shape="circle"/>
                            
                            <Divider layout="vertical" />
                        </div> */}


                    </div>

                    <div className="col-7 row">
                        <div className="form-group col-12">
                            <label>Email</label>
                            <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.email} disabled/>
                        </div>
                        <div className="form-group col-4">
                            <label>First Name</label>
                            <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.first_name} onChange={(e) => this.changeValue('first_name', e)}/>
                        </div>
                        <div className="form-group col-4">
                            <label>Middle Initial</label>
                            <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.middle_initial} onChange={(e) => this.changeValue('middle_initial', e)}/>
                        </div>
                        <div className="form-group col-4">
                            <label>Last Name</label>
                            <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.last_name} onChange={(e) => this.changeValue('last_name', e)}/>
                        </div>
                        <div className="form-group col-3">
                            <label>PRC Number</label>
                            <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.prc_number} onChange={(e) => this.changeValue('prc_number', e)}/>
                        </div>
                        <div className="form-group col-3">
                            <label>TIN Number</label>
                            <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.tin_number} onChange={(e) => this.changeValue('tin_number', e)}/>
                        </div>
                        <div className="form-group col-3">
                            <label>Expiration</label>
                            {/* <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.prc_number}/> */}
                        
                            <Calendar className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.expiration} onChange={(e) => this.changeValue('expiration', e)} />
                        </div>
                        <div className="form-group col-3">
                            <label>Status</label>
                            <Dropdown className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.active}  onChange={this.onStatusChange}  options={user_status} optionLabel="status" optionValue="value" placeholder="Select a Status" />      
                        </div>

                        <div className="form-group col-6">
                            <label>Office Address</label>
                            <InputTextarea rows={3} className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.office_address}  onChange={(e) => this.changeValue('office_address', e)}/>
                            {/* onChange={(e) => setValue(event.target.value)} */}
                        </div>
                        <div className="form-group col-6">
                            <label>Home Address</label>
                            <InputTextarea rows={3} className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.home_address}  onChange={(e) => this.changeValue('home_address', e)}/>
                        </div>
                    </div>

                    
                <div className="col-5">
                            <img src={serverUrl+this.state.form.prc_id} style={{height: '300px',float: 'left'}} />
                        </div>
                </div>
            </div>
        );

    }

}
