import { useState, CSSProperties, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Route, Redirect } from 'react-router-dom'

import axios from 'axios';
import { setUserSession, apiUrl, setTokens, getToken } from '../../Utils/Common';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';




import './Login.scss';
import auth from "../../Auth/auth";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#009688",
};



const Login = (props)=> {
    
  
    useEffect(() => {
      isLogin();
      checkRemember();

    }, [props.data]);


    const [rememberMe, setRememberMe] = useState(false);
    const [initialize, setInitialize] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');




    // set remember me
    const checkRemember = (props)=>{
      let emailStore = localStorage.getItem('remember')
      if(emailStore){
        setRememberMe(true);
        setEmail(emailStore)
        return true;
      }
      return true;
    }



    const onRememberChange = (e) => {
      let rmmber = false
      if(e.checked)
        rmmber = true;
      else
        rmmber = false
        setRememberMe(rmmber);
    }

    const isLogin = ()=>{
      if(auth.isAuthenticated()){
        props.history.push('/');
      }
    }
  

   
    const header = (
        <img alt="Card" src="images/usercard.png" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
    );
    const footer = (
        <span>
            <Button label="Save" icon="pi pi-check" />
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary ml-2" />
        </span>
    );


 
  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios.post(`${apiUrl}User/login`, { email: email, password: password }).then(response => {
      setLoading(false);
      setTokens(response.data.data);
      // localStorage.setItem('tokens', response.data.data)
      if(rememberMe){
        localStorage.setItem('remember',email);
      }
      auth.login(()=>{
        props.history.push('/');
      });

    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401 || error.response.status === 403 || error.response.status === 404) setError(error.response.data.message);
      else{
        setError("Something went wrong. Please try again later.");
        setTimeout(()=>{
            setError("");
        }, 2000);
    } 
      

    });
  }

  const handleKeyDown = (e)=> {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }
 

  const form_html = (
      
    <div>
      <div className="mt-2">
        <InputText type="email" onKeyDown={handleKeyDown}  value={email} onChange={e => setEmail(e.target.value)} autoComplete="new-password" className="w-100" placeholder="Email" />
    </div>
    <div style={{ marginTop: 10 }}>
      <InputText  type="password" onKeyDown={handleKeyDown}  value={password} onChange={e => setPassword(e.target.value)} autoComplete="new-password"  className="w-100" placeholder="Password" />
    </div>
    <div className="col-12 p-0 mt-3">
      <Checkbox inputId="cb1" onChange={e => onRememberChange(e)} checked={rememberMe}></Checkbox>
      <label htmlFor="cb1" className="p-checkbox-label" style={{ marginLeft: 10, position:'absolute' }}>
        Remember Me
      </label>
    </div>

    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
    <Button className="w-100" label="Login"  value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading}/>
    </div>
  );

  return (


    
    <div className="content-inner-component">  
        
        <Card  className="login-container p-3" style={{ width: '25rem', marginBottom: '2em' }}>

          <img src="/assets/img/ccbi-full-logo.png" alt="Chamber of Customs Brokers, Inc." className="img-main-logo mb-2 text-center"/>

     

      
          { loading && <div className="text-center"> <div><ClipLoader color={'#fff'} loading={true} cssOverride={override} size={150} />  Loading... </div> </div>}
          { !loading && form_html}
          

      </Card>
    </div>
  );
}
 


const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
 
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
 
export default Login;