// transaction fee computation ((a*i)+a)*i

// return the user data from the session storage

// export const serverUrl = 'http://localhost/';
// export const serverUrl = 'https://ccbi-test.bermudezsoftwarelabs.com/';
export const serverUrl = 'https://ccbi-dev.bermudezsoftwarelabs.com/';
// export const serverUrl = 'https://myccbi.bermudezsoftwarelabs.com/';

// https://ccbi-test.bermudezsoftwarelabs.com/
// https://ccbi-test.bermudezsofwarelabs.com/

// export const apiUrl = '/api/';
export const apiUrl = serverUrl+'api/';

export const user_status = [
  {
    value: '0',
    status: 'For Verification'
  },
  {
    value: '1',
    status: 'Approved'
  },

];
export const approval_status = [
  {
    value: '1',
    status: 'For Approval'
  },
  {
    value: '-1',
    status: 'Denied'
  },

  {
    value: '2',
    status: 'Approved'
  },

  {
    value: '3',
    status: 'Approved for Special Member'
  },

];

export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
   
  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token') || null;
  }

  export const clearLogin = () =>{
    localStorage.removeItem('user');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token');
  }
   
  export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token') || null;
  }
   
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('tokens');
    localStorage.removeItem('user');
  }

  export const setTokens = (tokens)=>{
    localStorage.setItem('token', tokens.token);
    localStorage.setItem('refresh_token', tokens.refresh_token);
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    localStorage.setItem('tokens', token);
    localStorage.setItem('user', JSON.stringify(user));
  }