import React, { Component } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';




import '../MemberRecords.scss';
import MemberRecordsService from '../MemberRecordsService';
import '../../../Auth/interceptor';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


export class DocumentManagement extends Component {
    memberservice = null;
    _Mounted = false;


    constructor(props) {
        super(props);
        this.memberservice = MemberRecordsService;
        this.memberservice.setTab(0);
        
        this.table_size = 300;
        this.show_form = false;
        this.state = {
            loading: false,
            show_form: false,
            members: [],
            first: 1,
            rows: 50,
            total: 0,
            currentPage: 1,
            totalRecords: 0,
            lazyParams: {
                first: 0,
                rows: 50,
                page: 1,
                sortField: 'id',
                sortOrder: 1,
                filters: {
                    'name': { value: '', matchMode: 'contains' },
                    'country.name': { value: '', matchMode: 'contains' },
                    'company': { value: '', matchMode: 'contains' },
                    'representative.name': { value: '', matchMode: 'contains' },
                }
            }
        }


        this.items = [

                    {
                        label:'View/Edit',
                        icon:'pi pi-fw pi-user-edit',
                        command:()=>{ this.openModal() }
                    },
                    {
                        label:'Lodgement',
                        icon:'pi pi-fw pi-book'
                    },
                    {
                        separator:true
                    },
                    {
                        label:'Delete',
                        icon:'pi pi-fw pi-trash',
                        
                        command:()=>{ this.DeleteUser() }
                    },

                ];

        
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleResize();
        window.addEventListener('resize', this.handleResize)

    }


    handleResize() {
        this.table_size = window.innerHeight-290;
    }
    componentDidMount() {
        // alert();
        if(!this._Mounted){
            // this.getMembers();
            this._Mounted = true;
            
        }

    }



    openModal(){
        this.setState({show_form: true});
        // console.log(this.menu.hide());
        // alert();
    }

    getMembers = () => {
        let ins = this;
        ins.setState({loading: true})

        
        this.memberservice.getMembers(this.state.lazyParams).then(function (response) {
            // console.log(response);
            let data = response.data.data.users;
            let total = response.data.data.total;
            ins.setState( {
                members: data, 
                totalRecords: total, 
                loading: false});
          })
          .catch(function (error) {
            console.log(error);
          });;
      }


    onSort(event){
        this.setState({ lazyParams: event }, this.getMembers);
    }

    onPage(event) {
            this.setState({
                lazyParams: {
                    first: event.first,
                    rows: event.rows,
                    currentPage: event.page + 1
                }
            });
            this.getMembers();
        // alert();
    }

    DeleteUser = () => {
        confirmDialog({
            message: 'Are you sure you want to delete this user?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>{ this.openModal() },
        });
    };




    fieldNameTemplate = (rowData) => {
        return <span>{rowData.last_name} {rowData.first_name} {rowData.middle_initial} </span>;
    }
    PrcNumberTemplate = (rowData) => {
        return <span>{rowData.prc_number.padStart(7,'0')} </span>;
    }
    TinNumberTemplate = (rowData) => {
        return <span>{rowData.prc_number.padStart(7,'0')} </span>;
    }
    ValidDate = (rowData) => {
        return <span>{moment(rowData.expiration).format('MMM DD, YYYY')} </span>;
    }
    ActionTemplate = (rowData) => {
        return <Button icon="pi pi-bars" onClick={(event) => this.openAction(rowData, event)} className="p-button-raised p-button-rounded p-button-sm p-button-outlined" />;
    }
    openAction = (data, e) => {
        console.log(data);
        this.menu.toggle(e)
    }

    onHide = (name) => {

        this.setState({show_form: false});
    }

    renderHeader = (name)=> {
        return (
            <div>
                <i className="pi pi-user"></i>
                Profile
            </div>
        );
    }

    renderFooter = (name)=> {
        return (
            <div>
                <Button label="Save" icon="pi pi-check" onClick={() => this.onHide(name)} autoFocus className="p-button-sm" />
                <Button label="Close" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text p-button-sm" />
            </div>
        );
    }

    FormContent(){
        return (
            <div>
                    <div className="field">
                        <label htmlFor="username1" className="block">First Name</label>
                        <InputText id="username1" aria-describedby="username1-help" className="block"/>
                        {/* <small id="username1-help" className="block">Enter your username to reset your password.</small> */}
                    </div>
            </div>
        );

    }

    render() {

        const template = {
            layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            },
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    </React.Fragment>
                );
            },
        };
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.getMembers} />;
        const paginatorRight = <Button type="button" icon="pi pi-plus" className="p-button-text" />;

        return (

            
            <div>


                <Dialog visible={this.state.show_form} onHide={this.onHide} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}} draggable={false} resizable={false}
                footer={this.renderFooter('displayBasic2')}
                header="Member Profile"
                >
                    {this.FormContent()}
                </Dialog>
                

                <ConfirmDialog />
                <TieredMenu model={this.items} popup ref={el => this.menu = el} id="overlay_tmenu" />
                <div className="mt-3 mb-3">
                    <h5> <i className="pi pi-fw pi-book"></i> Document Management</h5>
                </div>
                <div className="card">
                    <DataTable value={this.state.members} 
                        size="small" 
                        lazy 
                        responsiveLayout="scroll" 
                        totalRecords={this.state.totalRecords}
                        onPage={this.onPage}
                        onSort={this.onSort} 
                        paginator 
                        first={this.state.lazyParams.first} 
                        rows={this.state.lazyParams.rows} 
                        paginatorTemplate={template}
                        paginatorLeft={paginatorLeft} 
                        paginatorRight={paginatorRight}
                        scrollable 
                        scrollHeight={this.table_size}
                        paginatorClassName="justify-content-end" className="mt-6"
                        loading={this.state.loading}
                        sortField={this.state.lazyParams.sortField} 
                        sortOrder={this.state.lazyParams.sortOrder}

                    >
                        <Column sortable field="id" header="ID" style={{width:'50px'}} filter ></Column>
                        <Column sortable field="last_name,first_name" header="Name" style={{width:'150px'}} body={this.fieldNameTemplate}></Column>
                        <Column sortable field="prc_number" header="PRC Number" style={{width:'150px'}} body={this.PrcNumberTemplate}></Column>
                        <Column sortable field="expiration" header="Valid Until" style={{width:'150px'}} body={this.ValidDate}></Column>
                        <Column sortable field="tin_number" header="TIN Number" style={{width:'150px'}}></Column>
                        <Column sortable field="office_address" header="Office Address" style={{width:'300px'}}></Column>
                        <Column sortable field="home_address" header="Home Address" style={{width:'300px'}}></Column>
                        <Column
                            field="category"
                            header="Action"
                            style={{width:'50px'}}
                            frozen
                            body={this.ActionTemplate}
                            alignFrozen="right"
                        ></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}