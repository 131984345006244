import React, { Component } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';



import '../MemberRecords.scss';
import MemberRecordsService from '../MemberRecordsService';
import '../../../Auth/interceptor';

import axios from 'axios'

export class CPDManagement extends Component {
    memberservice = null;
    _Mounted = false;
    constructor(props) {
        super(props);
        this.memberservice = MemberRecordsService;

        this.memberservice.setTab(2);
        this.state = {
            members: [],
            first: 1,
            rows: 10,
            currentPage: 1
            

        }
    }

    componentDidMount() {
        // alert();
        if(!this._Mounted){
            this.getMembers();
            this._Mounted = true;
            
        }

    }


    getMembers(){

        this.memberservice.getMembers();
    }

    onCustomPage1(event) {
        this.setState({
            first: event.first,
            rows: event.rows,
            currentPage: event.page + 1
        });
        alert();
    }

    render() {

        const template = {
            layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    </React.Fragment>
                );
            },
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            }
        };

        return (
            <div>
                
                <div className="mt-2">
                    <h5> <i className="pi pi-fw pi-book"></i> CPD Management</h5>
                </div>
                <div className="card">
                    <DataTable value={this.state.members} size="small" responsiveLayout="scroll" footer="Footer" 
                    paginator paginatorTemplate={template} first={this.state.first} rows={this.state.rows} onPage={this.onCustomPage}
                    paginatorClassName="justify-content-end" className="mt-6"
                    >
                        <Column field="id" header="ID"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="name" header="PRC Number"></Column>
                        <Column field="name" header="TIN Number"></Column>
                        <Column field="category" header="Office Address"></Column>
                        <Column field="quantity" header="Home Address"></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}