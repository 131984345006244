import React, {useEffect} from 'react';

import { PanelMenu } from 'primereact/panelmenu';
import { useHistory } from "react-router-dom";
import './Main.scss';
import GlobalService from '../../Utils/GlobalService';
// import Global from '../../Utils/Global';




const Sidemenu  = ({ visible, onChangeVisible  }) => {
    
  let history = useHistory();
    useEffect(() => {
        // alert();
        // console.log(items);
        let pathname = window.location.pathname.split('/');
        for(let i of items){
            // console.log(i);
            i.expanded = false;
        }
        if(pathname[1]=='certificates'){
            items[2].expanded = true;
        }
        if(pathname[1]=='members'){
            items[0].expanded = true;
        }
        if(pathname[1]=='payment'){
            items[1].expanded = true;
        }
       

      }, []);

    // let history = {};
    const changeRoute = (link) =>{
        history.push(link);
        onChangeVisible(false);
    }


    const items = [
        {
            label:'Member Records',
            expanded: true,
            items:[
                {
                    label:'Profile Management',
                    icon:'pi pi-fw pi-users',
                    command:()=>{
                        changeRoute('/members/profiles')
                    }
                },
                {
                    label:'Document Management',
                    icon:'pi pi-fw pi-book',
                    command:()=>{
                        changeRoute('/members/documents')
                    }
                },
                {
                    label:'CPD Managment',
                    icon:'pi pi-fw pi-star',
                    command:()=>{
                        changeRoute('/members/cpd')
                    }
                }
            ]
        },
        {
            label:'Payment and Dues',
            items:[
                {
                    label:'Payment History',
                    icon:'pi pi-fw pi-money-bill',
                    command:()=>{
        
                        changeRoute('/payment/history')
                    }
                },
                {
                    label:'Lodgment History',
                    icon:'pi pi-fw pi-history',
                    command:()=>{
        
                        changeRoute('/payment/lodgement')
                    }
                }
            ]
        },
        {
            label:'Member Certificates',
            items:[
                {
                    label:'Certificate of Good Standing',
                    icon:'pi pi-fw pi-list',
                    command:()=>{
        
                        changeRoute('/certificates/cgs')
                    }
                },
                {
                    label:'Event Certificate',
                    icon:'pi pi-fw pi-calendar-minus',
                    command:()=>{
        
                        changeRoute('/certificates/events')
                    }
                }
            ]
        },
        {
            label:'Events & Activities',
            icon:'pi pi-fw pi-calendar-plus',
            command:()=>{

                changeRoute('/events_and_activities')
            }
        },
        {
            label:'CCBI Info Pages',
            icon:'pi pi-fw pi-info-circle',
            command:()=>{

                changeRoute('/info_pages')
            }

            // items:[
            //     {
            //         label:'Static Info on CCBI Mandates',
            //         icon:'pi pi-fw pi-list'
            //     },
            //     {
            //         label:'Chapter Info',
            //         icon:'pi pi-fw pi-info'
            //     },
            //     {
            //         label:'Memo and Policies Management',
            //         icon:'pi pi-fw pi-folder'
            //     }
            // ]
        },
        // {
        //     label:'Message Inbox',
        //     icon:'pi pi-fw pi-envelope',
        //     command:()=>{
        //         changeRoute()
        //     }
        // },
   
    ];


    const header = (
        <div className="mb-3">
            <img alt="logo" src="/assets/img/logo.svg" height="100" className="mr-2 m-auto w-100"  />
        </div>
    );
    

    return (
        <div>
              {header}


              <PanelMenu model={items} style={{ width: '100%' }}/>
        </div>
    );
};
 
export default Sidemenu;