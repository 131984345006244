import React, { useState, useRef } from 'react';

import { BrowserRouter, Switch, Route, NavLink, Redirect  } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import auth from '../../Auth/auth';
import Sidemenu from './Sidemenu';

import { Card } from 'primereact/card';


import { MemberRecords } from '../MemberRecords/MemberRecords';

import { Activities } from '../EventsAndActivities/Activities';
import Home from '../Home';
import { PaymentLodgement } from '../PaymentLodgement/PaymentLodgement';
import { Certificates } from '../Certificates/Certificates';
import { InfoPages } from '../MemberRecords/Tabs/ProfileManagementComponents/InfoPages';










const Main = (props) => {

    const [visible, setVisible] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);

    const toast = useRef(null);

    const Logout = ()=>{
        confirmDialog({
            message: 'Are you sure you want to logout?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept
        });
    }
    const showLeftMenu = () =>{
        setVisibleLeft(true);
    }

    const accept = () => {
        auth.logout(()=>{
            props.history.push('/login');
        });
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirm1 = () => {
        confirmDialog({
            message: 'Are you sure you want to logout?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const onChangeVisible = (data) => {
        setVisibleLeft(false)
    };

    const side_html = (
        <div>
            <img alt="logo" src="assets/img/logo.svg" onClick={showLeftMenu} height="100" className="mr-2 m-auto w-100"  />
        </div>
    );

    const item = []


    const start = <Button  onClick={showLeftMenu} icon="pi pi-bars" height="40" className="p-button-sm p-button-outlined p-button-plain" />;
    const end = <Button label="Logout" icon="pi pi-fw pi-power-off" onClick={Logout} className="p-button-sm p-button-text p-button-plain"   />;

    let routes = (
        <Switch>
            <Route path="/members" component={MemberRecords} />
            <Route path="/events_and_activities" component={Activities} />
            <Route path="/payment" component={PaymentLodgement} />
            <Route path="/certificates" component={Certificates} />
            <Route path="/info_pages" component={InfoPages} />
            <Route path="/" component={Home} />
            <Route path="/hello" component={ ()=> "404 ss FOUND"} />
            <Route path="*" component={ ()=> "404 NOT FOUND"} />
        </Switch>
      );

    return (
        <div>
              <Toast ref={toast} />
              <ConfirmDialog />
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                   <Sidemenu  visible={visibleLeft} onChangeVisible={onChangeVisible} />
            </Sidebar>

            <div className="page-bg">
                <Menubar model={item} start={start} end={end} />
                {/* <div className="card page-container" style="width: 18rem;"> */}
                   
                {/* </div> */}
            <div className="card page-container p-2 pl-3 pr-3">
                {routes}
            </div>


    {/* member certificate */}
{/* certificate of good standing */}
{/* events certificate */}

            </div>
        </div>
    );
}
 
export default Main;