import React, { Component } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Badge } from 'primereact/badge';
import { serverUrl, apiUrl } from '../../Utils/Common';




import './Activities.scss';
import ActivitiesService from './ActivitiesService';
import '../../Auth/interceptor';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import GlobalService from '../../Utils/GlobalService';
import { ActivitiesModal } from './ActivitiesModal';




export class Activities extends Component {
    activitiesservice = null;
    _Mounted = false;


    constructor(props) {
        super(props);
        this.activitiesservice = ActivitiesService;
        this.activitiesservice.setTab(0);

        this.new_data = {};
        
        this.table_size = 300;
        this.show_form = false;
        this.state = {
            dialog_loader: false,
            activeTab: 0,
            form:{

            },
            loading: false,
            show_form: false,
            events: [],
            first: 1,
            rows: 50,
            total: 0,
            currentPage: 1,
            totalRecords: 0,
            lazyParams: {
                first: 0,
                rows: 50,
                page: 1,
                sortField: 'id',
                sortOrder: -1,
                filters: {
                    'name': { value: '', matchMode: 'contains' },
                    'country.name': { value: '', matchMode: 'contains' },
                    'company': { value: '', matchMode: 'contains' },
                    'representative.name': { value: '', matchMode: 'contains' },
                }
            }
        }

        this.status = [
            { name: 'New', code: '0' },
            { name: 'Ongoing', code: '1' },
            { name: 'Done', code: '2' },
            { name: 'Closed', code: '3' },
        ];
          


        this.items = [

                    {
                        label:'View/Edit',
                        icon:'pi pi-fw pi-pencil',
                        command:(e)=>{ 
                            this.openModal(e) 
                        }
                    },
                    {
                        label:'Delete',
                        icon:'pi pi-fw pi-trash',
                        
                        command:()=>{ this.DeleteUser() }
                    },

                ];


                this.items_dialog = [
                    {
                        label:'User Details',
                        icon:'pi pi-fw pi-user',
                        command:()=>{
                            // this.setState({activeTab:0});
                        }
                    },
                    {
                        label:'Lodgement',
                        icon:'pi pi-fw pi-book',
                        command:()=>{
                            // this.setState({activeTab:1});
                        }
                    },
                    {
                        label:'Payment History',
                        icon:'pi pi-fw pi-money-bill',
                        command:()=>{
                            // this.setState({activeTab:2});
                        }
                    }
                ]

        
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.setForm = this.setForm.bind(this);
        this.newModal = this.newModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.viewJoiners = this.viewJoiners.bind(this);
        this.newEventData = this.newEventData.bind(this);
        this.handleResize();
        window.addEventListener('resize', this.handleResize)

    }


    handleResize() {
        this.table_size = window.innerHeight-290;
    }
    componentDidMount() {
        // alert();
        if(!this._Mounted){
            this.getEvents();
            this._Mounted = true;
            
        }

    }



    openModal(){
        this.setState({show_form: true});
        // console.log(this.menu.hide());
        // alert();
    }

    getEvents = () => {
        let ins = this;
        ins.setState({loading: true})

        
        this.activitiesservice.getEvents(this.state.lazyParams).then(function (response) {
            // console.log(response);
            let data = response.data.data.events;
            let total = response.data.data.total;
            ins.setState( {
                events: data, 
                totalRecords: total, 
                loading: false});
          })
          .catch(function (error) {
            console.log(error);
          });;
      }


    onSort(event){
        this.setState({ lazyParams: event }, this.getEvents);
    }
    setForm(event){
        this.setState({ form: event });
    }

    onPage(event) {
            this.setState({
                lazyParams: {
                    first: event.first,
                    rows: event.rows,
                    currentPage: event.page + 1
                }
            });
            this.getEvents();
        // alert();
    }

    DeleteUser = () => {
        confirmDialog({
            message: 'Are you sure you want to delete this event?',
            header: 'Delete Event',
            icon: 'pi pi-trash',
            accept: ()=>{

                let obj = this;
                this.activitiesservice.delete(this.state.form.id).then(function (response) {
                    console.log(obj.state.events);
                    let ev = obj.state.events;
                    if(response.data.data){
                        // alert();
                        obj.toast.show({severity:'success', summary: 'Success', detail:'Successfully Deleted.', life: 3000});           
                        
                        
                        let event_index = GlobalService.getDataIndex(ev,'id', obj.state.form.id);
                        // alert(event_index);
                        ev.splice(event_index, 1);
                        obj.setState({events: ev});
                            

                    }

                }).catch(function (error) {
                      console.log(error);
                    obj.toast.show({severity:'danger', summary: 'Warn Message', detail:'Something went wrong.', life: 3000});
                      obj.setState({dialog_loader: false});
                    });;

             },
        });
    };


    qrTemplate = (rowData) => {
            return <Image lt="Image" width="50" preview  src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(`${serverUrl}api/Events/action/${rowData.hash_code}/in`)}&size=220x220&margin=0`} alt="Image Text" />;
    }

    qrTemplateOut = (rowData) => {
            return <Image lt="Image" width="50" preview  src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(`${serverUrl}api/Events/action/${rowData.hash_code}/out`)}&size=220x220&margin=0`} alt="Image Text" />;
    }

    ValidDate = (rowData, column) => {
        return <span>{moment(rowData[column.field]).format('MMM DD, YYYY')} </span>;
    }
    getStatus = (rowData, column) => {
        let dat = GlobalService.getData(this.status,'code', rowData.status);
        return <span>{dat.name} </span>;
    }
    ActionTemplate = (rowData) => {
        return <Button icon="pi pi-bars" onClick={(event) => this.openAction(rowData, event)} className="p-button-raised p-button-rounded p-button-sm p-button-outlined m-auto" />;
    }
    openAction = (data, e) => {
        this.setForm(data);
        this.menu.toggle(e)
    }
    newModal = () => {
        this.setForm({})
        this.openModal();
    }

    onHide = (name) => {

        this.setState({show_form: false});
    }
    viewJoiners = () => {
        console.log(this.state.form);
    }

    saveUser = (name) => {
        
        // console.log(this.new_data);

        let obj = this;

        
        
        if(Object.entries(this.new_data).length === 0){
            this.toast.show({severity:'warn', summary: 'Warn Message', detail:'You dont have any changes.', life: 3000});
        }else{
            // console.log(this.new_data);
            
            this.new_data.start_date = moment(this.new_data.start_date).format('Y-M-D');
            let save_data = JSON.parse(JSON.stringify(this.new_data));

            if(save_data.subscribed){
                delete save_data.subscribed;
            }
            if(save_data.current){
                delete save_data.current;
            }
            obj.setState({dialog_loader: true});
            this.activitiesservice.updateUser(save_data).then(function (response) {
                if(response.data.status_code==200){
                    console.log(response.data.data);
                    obj.setForm(response.data.data);
                    obj.setState({show_form: false});

                    console.log(obj.state.members);
                    let user_index = GlobalService.getDataIndex(obj.state.events,'id', response.data.data.id);

                    console.log(user_index);
                    if(user_index!=undefined){
                        let new_data = response.data.data;
                        for(let d in response.data.data){
                            obj.state.events[user_index][d] = new_data[d];
                        }
                        // obj.state.events[user_index] = response.data.data;
                        obj.setState({events: obj.state.events});
                        
                        obj.toast.show({severity:'success', summary: 'Success Message', detail:'Event seccessfully updated.', life: 3000});
                    }else{
                        obj.state.events.unshift(response.data.data);
                        // console.log(obj.state.events)
                        obj.setState({events: obj.state.events});
                        obj.toast.show({severity:'success', summary: 'Success Message', detail:'Event seccessfully created.', life: 3000});
                    }
                    
                    obj.setState({dialog_loader: false});
                }

              })
              .catch(function (error) {
                console.log(error);
                
                this.toast.show({severity:'danger', summary: 'Warn Message', detail:'Something went wrong.', life: 3000});
                obj.setState({dialog_loader: false});
              });;
        }

    }

    renderHeader = (name)=> {
        return (
            <div>
                <i className="pi pi-user"></i>
                Profile
            </div>
        );
    }

    renderFooter = (name)=> {
        return (
            <div>
                {/* <Button label="View Joiners" icon="pi pi-users" onClick={() => this.viewJoiners()} autoFocus className="p-button-sm pull-left" >
                    <Badge value={this.state.form.subscribed}></Badge>
                </Button> */}

                <Button label="Save" icon="pi pi-check" onClick={() => this.saveUser(name)} autoFocus className="p-button-sm" />
                <Button label="Close" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text p-button-sm" />
            </div>
        );
    }

    newEventData = (langValue) => {
        console.log(langValue);
        this.new_data = langValue;
    }

    FormContent(){
        return (
            <div className="row">
                <div className="form-group col-4">
                    <label>First Name</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.first_name} onChange={(e) => this.setState({ form:{ first_name: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>Middle Initial</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.middle_initial} onChange={(e) => this.setState({ form:{ middle_initial: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>Last Name</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.last_name} onChange={(e) => this.setState({ form:{ last_name: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>PRC Number</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.prc_number} onChange={(e) => this.setState({ form:{ prc_number: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>TIN Number</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.tin_number} onChange={(e) => this.setState({ form:{ tin_number: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>Expiration</label>
                    {/* <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.prc_number}/> */}
                    
                    <Calendar className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.expiration} onChange={(e) => this.setState({ form:{ expiration: e.value}  })} />
                </div>
                <div className="form-group col-6">
                    <label>Office Address</label>
                    <InputTextarea rows={3} className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.office_address}  onChange={(e) => this.setState({ form:{ office_address: e.value}  })}/>
                    {/* onChange={(e) => setValue(event.target.value)} */}
                </div>
                <div className="form-group col-6">
                    <label>Home Address</label>
                    <InputTextarea rows={3} className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.home_address}  onChange={(e) => this.setState({ form:{ home_address: e.value}  })}/>
                </div>
            </div>
        );

    }

    render() {
        

        const template = {
            layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            },
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    </React.Fragment>
                );
            },
        };
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.getEvents} />;
        const paginatorRight = <Button type="button" icon="pi pi-plus" className="p-button-text" />;

        const loader = <div className="dialog-loader"><i className="fa fa-spin fa-spinner"></i> Loading...</div>;

        return (

            
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Dialog 
                    visible={this.state.show_form} 
                    onHide={this.onHide} 
                    breakpoints={{'960px': '85vw', '840px': '100vw'}} 
                    style={{width: '95vw', height: '95vh'}} 
                    draggable={false} 
                    resizable={false}
                    footer={this.renderFooter('displayBasic2')}
                    header="Events & Activities"
                >

                    <div className="">
                        {(this.state.dialog_loader) && loader} 

                        <ActivitiesModal eventData={this.state.form} changeData={this.newEventData}/>
                    </div>

                </Dialog>
                

                {/* <ConfirmDialog /> */}
                <TieredMenu model={this.items} popup ref={el => this.menu = el} id="overlay_tmenu" />
                <div className="mt-3 mb-4">
                    <h5 className="float-left pt-2 pb-2"> <i className="pi pi-fw pi-calendar"></i> Events & Activities Management</h5>
                    <Button icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-sm p-button-outlined float-right" onClick={this.newModal}/>
                </div>

                <div className="card w-100 mt-5">
                    <DataTable value={this.state.events} 
                        size="small" 
                        lazy 
                        responsiveLayout="scroll" 
                        totalRecords={this.state.totalRecords}
                        onPage={this.onPage}
                        onSort={this.onSort} 
                        paginator 
                        first={this.state.lazyParams.first} 
                        rows={this.state.lazyParams.rows} 
                        paginatorTemplate={template}
                        paginatorLeft={paginatorLeft} 
                        paginatorRight={paginatorRight}
                        scrollable 
                        scrollHeight={this.table_size}
                        paginatorClassName="justify-content-end" className="mt-6"
                        loading={this.state.loading}
                        sortField={this.state.lazyParams.sortField} 
                        sortOrder={this.state.lazyParams.sortOrder}
                        showGridlines
                        

                    >
                        <Column  field="id" header="ID" style={{width:'50px'}}  className="small-width" ></Column>
                        <Column  field="qr_code"  header="QR Code IN" style={{width:'150px'}} body={this.qrTemplate} ></Column>
                        <Column  field="qr_code"  header="QR Code OUT" style={{width:'150px'}} body={this.qrTemplateOut} ></Column>
                        <Column  field="event_name"  header="Name" style={{width:'150px'}} ></Column>
                        <Column  field="event_description"  header="Description" style={{width:'150px'}} ></Column>
                        <Column  field="start_date"  header="Start Date" style={{width:'150px'}} body={this.ValidDate}></Column>
                        <Column  field="participant"  header="Participant" style={{width:'80'}}></Column>
                        <Column  field="subscribed"  header="Registered" style={{width:'80'}}></Column>
                        <Column  field="current"  header="Current Attendees" style={{width:'80'}}></Column>
                        <Column  field="status"  header="Status" style={{width:'100px'}} body={this.getStatus}></Column>
                        <Column
                            field="category"
                            header="Action"
                            frozen
                            className="small-width text-center"
                            body={this.ActionTemplate}
                            alignFrozen="right"
                        ></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}