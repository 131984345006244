import { getToken, getRefreshToken, setTokens, apiUrl } from '../Utils/Common';
import axios from 'axios'
// import router from './router/router'

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    config.baseURL = `${apiUrl}`;
    return config
  },
  error => {
    Promise.reject(error)
  }
)



axios.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      const originalRequest = error.config
  
      if (
        error.response.status === 401 &&
        originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
      ) {
        // router.push('/login')
        return Promise.reject(error)
      }
  
      if(error.response.status === 401 && !originalRequest._retry ){
        originalRequest._retry = true
        // alert(getRefreshToken());
        return axios
            .post('/User/refreshToken', {
              refresh_token: getRefreshToken()
            })
            .then(res => {

            })
      }
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true
      //   const refreshToken = getRefreshToken()
      //   return axios
      //     .post('/User/refreshToken', {
      //       refresh_token: refreshToken
      //     })
      //     .then(res => {
      //       if (res.status === 401) {
      //       }else if (res.status === 201) {
      //           setTokens(res.data)
      //         axios.defaults.headers.common['Authorization'] =
      //           'Bearer ' + getToken()
      //         return axios(originalRequest)
      //       }
      //     })
      // }
      return Promise.reject(error)
    }
  )