import React, { Component } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { BrowserRouter, Switch, Route, NavLink, Redirect  } from 'react-router-dom';
import './MemberRecords.scss';
import MemberRecordsService from './MemberRecordsService';
import GlobalService from '../../Utils/GlobalService';
import { ProfileManagement } from './Tabs/ProfileManagement';
import { DocumentManagement } from './Tabs/DocumentManagement';
import { CPDManagement } from './Tabs/CPDManagement';





export class MemberRecords extends Component {
    

    constructor(props) {
        super(props);
        this.activeIndex = 1;
        this.globalservice = GlobalService;
        this.memberservice = MemberRecordsService;
    }
    activeIndex = 0

    template(options){

    }

    changeRoute = (link) =>{
        // alert(link)
        this.props.history.push({
            pathname: link,
            state: {}
        });
    }


    setActiveIndex(index){
        this.memberservice.setTab(index)
        // alert(index);
        // this.MemberRecordsService.test();
    }


    items = [
        {
            label:'Profile Management',
            icon:'pi pi-fw pi-users',
            command:()=>{
                this.changeRoute('/members/profiles')
            }
        },
        {
            label:'Document Management',
            icon:'pi pi-fw pi-book',
            command:()=>{
                this.changeRoute('/members/documents')
            }
        },
        {
            label:'CPD Managment',
            icon:'pi pi-fw pi-star',
            command:()=>{
                this.changeRoute('/members/cpd')
            }
        }
    ];
  
    render() {
        // const scrollableTabs = Array.from({ length: 50 }, (_, i) => ({ title: `Tab ${i + 1}`, content: `Tab ${i + 1} Content` }))

        return (
            <div className="tabview-demo">
                 <TabMenu model={this.items} activeIndex={this.memberservice.getTab()} onTabChange={(e) => this.setActiveIndex(e.index)}/>

                <div className="inner-content">
                    <Switch>
                          <Route path="/members/profiles" component={ProfileManagement} />
                          <Route path="/members/documents" component={DocumentManagement} />
                          <Route path="/members/cpd" component={CPDManagement} />
                    </Switch>
                </div>
     
            </div>
        )
    }
}