
import {axiosInstance} from '../../Auth/interceptor';
import axios from 'axios'
import { apiUrl } from '../../Utils/Common';


class MemberRecordsService {
    static classInstance = null;
    static getInstance() {
        if (MemberRecordsService.classInstance === null) {
            MemberRecordsService.classInstance = new MemberRecordsService();
        }
        return this.classInstance;
    }

    default_tab = 0;
    constructor() {
    
    }
    setTab(tab){
        this.default_tab = tab;
    }
    getTab(){
       return this.default_tab;
    }


    getMembers(params){

        return axios.post('Member/list/',params)
    }

    updateUser(data){
        delete data.email;
        return axios.put('Member/user', data)
      }

}

  


export default MemberRecordsService.getInstance();

