
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { Checkbox } from 'primereact/checkbox';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Activities.scss'



import { serverUrl, apiUrl } from '../../Utils/Common';

import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Image } from 'primereact/image';
import { TabMenu } from 'primereact/tabmenu';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ActivitiesService from './ActivitiesService';
import { Avatar } from 'primereact/avatar';

import moment from 'moment';

export class ActivitiesModal extends Component {
    activitiesservice = ActivitiesService;
    _Mounted = false;


    constructor(props) {
        super(props);


        let new_data = {status:0};
        let editorstate = EditorState.createEmpty();
        if(this.props.eventData){
            new_data = JSON.parse(JSON.stringify(this.props.eventData));

            if(new_data.start_date){
                new_data.start_date = new Date(new_data.start_date);
            }
            if(new_data.html_content){
                editorstate = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(new_data.html_content)
                    )
                  );
            }


        }else{
            setTimeout(()=>{
                this.setState({ status: '0' });
            },600);
        }

        this.state = {
            joiners_called: false,
            joiners: [],
            joiners_2: [],
            activeIndex: 0,
            editorState: editorstate,
            form: new_data
          };



        this.joiner_data = [];
          this.status = [
            { name: 'New', code: '0' },
            { name: 'Ongoing', code: '1' },
            { name: 'Done', code: '2' },
            { name: 'Closed', code: '3' },
        ];
          
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.callJoiners = this.callJoiners.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        
        this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);

        this.itemstab = [
            {label: 'Event Details', icon: 'pi pi-fw pi-list'},
            {label: 'Attendance', icon: 'pi pi-fw pi-users'},
        ];

        this.cols = [
            { field: 'full_name', header: 'Full Name' },
            { field: 'expiration', header: 'Valid Until' },
            { field: 'prc_number', header: 'PRC ID #' },
            { field: 'participation', header: 'Participation' }
        ];

        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));


    }

    
    exportPdf() {
        let t = this;
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.setFontSize(20);
                doc.setTextColor(40);
                doc.text(`${this.state.form.event_name} - ${moment(this.state.form.start_date).format('MMM DD, YYYY')}`, 15, 10);

                doc.autoTable(this.exportColumns, this.joiner_data);
                doc.save(`${this.state.form.event_name} - ${moment(this.state.form.start_date).format('MMM DD, YYYY')} attendance.pdf`);
            })
        })
    }
    exportExcel() {
        import('xlsx').then(xlsx => {

            let new_data = [];

            for(let j of this.joiner_data){
                new_data.push({'Last Name':j.last_name, 'First Name':j.first_name, 'Middle Name':j.middle_initial, 'Valid Until':j.expiration, 'PRC ID #':j.prc_number, 'Participation':j.participation});
            }

            
            const worksheet = xlsx.utils.json_to_sheet(new_data);
            worksheet["!cols"] = [ { wch: 15 },{ wch: 15 },{ wch: 15 }, { wch: 15 } , { wch: 15 }, { wch: 15 } ];
            const workbook = { Sheets: { 'Attendees': worksheet }, SheetNames: ['Attendees'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, `${this.state.form.event_name} - ${moment(this.state.form.start_date).format('MMM DD, YYYY')} attendance`);
        });
    }
    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }



    onBasicUploadAuto(rowData) {
        // alert();
        let r = JSON.parse(rowData.xhr.response);
        if(r.data){
            this.toast.show({severity: 'info', summary: 'Success', detail: 'File Successfully Uploaded.'});
            this.state.form['event_img'] = r.data.event_img;
            this.state.form['img_thumbnail'] = r.data.img_thumbnail;
            // console.log(this.state);
            this.setState(this.state);
            this.props.changeData(this.state.form);
        }

    }

    onStatusChange(e) {
        this.state.form['status'] = e.value;
        this.setState(this.state);
        this.props.changeData(this.state.form);
    }

    onCheckChange(e) {
        let val = '0';
        if(e.checked){
            val = '1';
        }
        this.state.form['for_reservation'] = val;
        this.setState(this.state);
        this.props.changeData(this.state.form);
    }

    
    changeValue(name, val){
        // console.log(event);
        if(name=='start_date'){
            this.state.form[name] = moment(val).format('Y-M-D');
        }else{
            this.state.form[name] = val;
        }


        this.setState(this.state)
        this.props.changeData(this.state.form);
    }

    changeTab(data){
        this.setState({ activeIndex: data })
        if(data==1){
            this.callJoiners();
        }
    }

    onEditorStateChange: Function = (editorState) => {
        // console.log(editorState.currentContent);
        this.setState({
          editorState,
        });

        console.log(this.state.editorState.getCurrentContent().getPlainText());
        const html_content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.state.form.html_content = html_content;
        this.props.changeData(this.state.form);
        // console.log(this.state.editorState.setCurrentContent(''));
        
      };

    renderImg = ()=> {
        return (
            <div>
                dsadsadas
            </div>
        )
    }
    callJoiners = ()=> {
        let ins = this;
        this.activitiesservice.getJoiners(this.state.form.id).then(function (response) {
            // console.log(response);
            let data = response.data.data;
            let new_joiners = [];
            for(let j of data){
                let part = (j.participation=='1') ? 'Attended' : 'Did not attend';
                let new_data = {last_name:j.last_name, first_name:j.first_name, middle_initial: j.middle_initial, full_name: `${j.last_name}, ${j.first_name} ${j.middle_initial}`,  prc_number: j.prc_number.padStart(7,'0'), participation: part, expiration: moment(j.expiration).format('MMM DD, YYYY')};
                ins.joiner_data.push(new_data);
            }
            
            ins.setState( {joiners: data});
        })
          .catch(function (error) {
            console.log(error);
          });;
    }   

    fieldNameTemplate = (rowData) => {
        let avatar = <Avatar label={rowData.first_name.charAt(0)+rowData.last_name.charAt(0)} className="mr-2" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />;
        if(rowData.member_picture){
            avatar = <Avatar image={`${serverUrl}${rowData.member_picture}`} className="mr-2" shape="circle" />;
        }

        return <span  className="user-full-name" tooltip="Enter your username" > 
                {avatar}
                {rowData.last_name} {rowData.first_name} {rowData.middle_initial} 
            </span>;
    }

    fieldParticipation = (rowData) => {
        if(Number(rowData.participation)==1){
            return <span  className="user-attended" > Attended </span>;
        }

        return <span  className="user-notattended" > Did Not Attend </span>;
    }

    ValidDate = (rowData, column) => {
        return <span>{moment(rowData[column.field]).format('MMM DD, YYYY')} </span>;
    }

    fieldPrcNumberTemplate = (rowData) => {
        return <span>{rowData.prc_number.padStart(7,'0')} </span>;
    }
    renderTable = ()=> {

        // First name, middle, last name 
        // prc number
        // participation - attended|did not attend
        // this.callJoiners()
        
        return (
            <div>
                  <Button type="button" icon="pi pi-file-pdf" onClick={this.exportPdf} label="PDF Download" className="p-button-warning mr-2 mb-2" data-pr-tooltip="PDF" />
                  <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} label="Excel Download" className="p-button-success mr-2 mb-2" data-pr-tooltip="EXCEL" />
                <div className="card">
                    <DataTable value={this.state.joiners} responsiveLayout="scroll">
                        <Column field="full_name" header="Full Name" body={this.fieldNameTemplate}></Column>
                        <Column field="expiration" header="Valid Until" body={this.ValidDate}></Column>
                        <Column field="prc_number" header="PRC ID #" body={this.fieldPrcNumberTemplate}></Column>
                        <Column field="participation" header="Participation" body={this.fieldParticipation}></Column>
                        <Column field="created_date" header="Date Registered" body={this.ValidDate}></Column>
                    </DataTable>
                </div>
            </div>
        )
    }

    renderForm = ()=> {
        
        const { editorState } = this.state;
        return (
            <div>
                                    
                    <div className="w-100 float-left mb-2">
                        
                        <FileUpload mode="basic" className="p-button-raised p-button-sm p-button-outlined m-auto float-left" name="file" url={apiUrl+'Upload/start'} accept="image/*" maxFileSize={1000000} onUpload={this.onBasicUploadAuto} auto chooseLabel="Upload Image" />
                        
                        {this.state.form.event_img && 
                        <Image lt="Image" height="50" style={{ margin:'0px 10px'}} preview  src={serverUrl+this.state.form.event_img} alt="Image Text" />}
                    
                        
                    <div className="pull-right mt-2">
                        <Checkbox inputId="for_reservation" name="for_reservation" value="for_reservation" onChange={this.onCheckChange} checked={this.state.form.for_reservation=='1'} />
                        <label htmlFor="for_reservation" style={{margin: '0px 5px'}}>With Reservation</label>
                    </div>

                    </div>

                                    <Divider />
                                    <div className="col-6 pull-left">
                                        <div className="row">

                        
                                            <div className="form-group col-6">
                                                <label>Event Name</label>
                                                <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.event_name} onChange={(e) => this.changeValue('event_name', e.target.value)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Total Participants</label>
                                                <InputNumber  className="p-0 p-inputtext-sm block mb-1 col-12"  value={this.state.form.participant} onChange={(e) => this.changeValue('participant', e.value)}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>CDP Points</label>
                                                <InputNumber className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.cpd_points} onChange={(e) => this.changeValue('cpd_points', e.value)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Status</label>
                                                <Dropdown className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.status}  onChange={this.onStatusChange}  options={this.status} optionLabel="name" optionValue="code" placeholder="Select a Status" />      
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Amount</label>
                                                <InputNumber className="p-inputtext-sm block mb-1 col-12 p-0"  value={this.state.form.amounts} onChange={(e) => this.changeValue('amounts', e.value)}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Start Date</label>
                                                <Calendar  className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.start_date} onChange={(e) => this.changeValue('start_date', e.target.value)} />
                                            </div>
                                            <div className="form-group col-12">
                                                <label>Event Description</label>
                                                <InputTextarea rows={3} cols={30} className="p-inputtext-sm block mb-1 col-12 p-1" value={this.state.form.event_description} onChange={(e) => this.changeValue('event_description', e.target.value)}/>
                                            </div>
                                        
                                        </div>
                                    
                                    </div>


                                    <div className="col-6 pull-left">

                                    <div className="form-group col-12">
                                                <label>Certification Citation</label>
                                                <InputTextarea rows={3} cols={30} className="p-inputtext-sm block mb-1 col-12 p-1" value={this.state.form.citation} onChange={(e) => this.changeValue('citation', e.target.value)}/>
                                            </div>

                                        
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.onEditorStateChange}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            toolbar={
                                                {
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily' ],
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                        
                                                }
                                            }
                                                        />
                                    </div>


            </div>
        )
    }
    

 
    render() {



        return (


            <div>
                
                            <Toast ref={(el) => this.toast = el} />
                

                            <TabMenu model={this.itemstab} activeIndex={this.state.activeIndex} onTabChange={(e) => this.changeTab(e.index)}  className="mb-2"/>

                            {this.state.activeIndex==0 &&  this.renderForm()}
                            {this.state.activeIndex==1 &&  this.renderTable()}


            </div>
        );
    }


}


    