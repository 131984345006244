import React, { Component } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
import debounce from 'lodash.debounce';
import { apiUrl, serverUrl } from '../../../Utils/Common';





import '../MemberRecords.scss';
import MemberRecordsService from '../MemberRecordsService';

import '../../../Auth/interceptor';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { UserDetails } from './ProfileManagementComponents/UserDetails';
import { UserLodgement } from './ProfileManagementComponents/UserLodgement';
import { PaymentHistory } from './ProfileManagementComponents/PaymentHistory';
import GlobalService from '../../../Utils/GlobalService';
import PaymentHistoryService from '../PaymentHistoryService';




export class ProfileManagement extends Component {
    memberservice = null;
    paymentservice = null;
    _Mounted = false;


    constructor(props) {
        super(props);
        this.memberservice = MemberRecordsService;
        this.paymentservice = PaymentHistoryService;
        this.memberservice.setTab(0);

        this.new_data = {};
        
        this.table_size = 300;
        this.show_form = false;
        this.state = {
            dialog_loader: false,
            payment_loader: false,
            activeTab: 0,
            form:{

            },
            loading: false,
            payment_called: false,
            show_form: false,
            members: [],
            payment_history: [],
            first: 1,
            rows: 50,
            total: 0,
            currentPage: 1,
            totalRecords: 0,
            lazyParams: {
                search_text:'',
                first: 0,
                rows: 50,
                page: 1,
                sortField: 'id',
                sortOrder: -1,
                filters: {
                    'name': { value: '', matchMode: 'contains' },
                    'country.name': { value: '', matchMode: 'contains' },
                    'company': { value: '', matchMode: 'contains' },
                    'representative.name': { value: '', matchMode: 'contains' },
                }
            }
        }


        this.items = [

                    {
                        label:'View/Edit',
                        icon:'pi pi-fw pi-user-edit',
                        command:(e)=>{ 
                            this.openModal() 
                        }
                    },
                    {
                        label:'Lodgement',
                        icon:'pi pi-fw pi-book'
                    },
                    {
                        separator:true
                    },
                    {
                        label:'Delete',
                        icon:'pi pi-fw pi-trash',
                        
                        command:()=>{ this.DeleteUser() }
                    },

                ];


                this.items_dialog = [
                    {
                        label:'User Details',
                        icon:'pi pi-fw pi-user',
                        command:()=>{
                            // this.setState({activeTab:0});
                        }
                    },
                    {
                        label:'Lodgement',
                        icon:'pi pi-fw pi-book',
                        command:()=>{
                            // this.setState({activeTab:1});
                        }
                    },
                    {
                        label:'Payment History',
                        icon:'pi pi-fw pi-money-bill',
                        command:()=>{
                            // this.setState({activeTab:2});
                        }
                    }
                ]

        
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.setForm = this.setForm.bind(this);
        this.callPayments = this.callPayments.bind(this);
        this.searchText = this.searchText.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.newUserData = this.newUserData.bind(this);
        this.handleResize();
        this.onChangeDebounced = debounce(this.onChangeDebounced, 1000)
        window.addEventListener('resize', this.handleResize)

    }


    handleResize() {
        this.table_size = window.innerHeight-290;
    }
    componentDidMount() {
        // alert();
        if(!this._Mounted){
            this.getMembers();
            this._Mounted = true;
            
        }

    }



    openModal(){
        this.setState({show_form: true, payment_called: false});
        this.changeTab(0);
        // console.log(this.menu.hide());
        // alert();
    }

    searchText = (data) => {
        this.state.lazyParams['search_text'] = data.target.value;
        this.setState(this.state)
        this.onChangeDebounced(data);
    }

    
      onChangeDebounced = (e) => {
        // Delayed logic goes here
        this.getMembers();
      }


    getMembers = () => {
        let ins = this;
        ins.setState({loading: true})

        
        this.memberservice.getMembers(this.state.lazyParams).then(function (response) {
            // console.log(response);
            let data = response.data.data.users;
            let total = response.data.data.total;
            ins.setState( {
                members: data, 
                totalRecords: total, 
                loading: false});
          })
          .catch(function (error) {
            console.log(error);
          });;
      }


    onSort(event){
        this.setState({ lazyParams: event }, this.getMembers);
    }
    setForm(event){
        this.setState({ form: event });
    }

    onPage(event) {

        this.state.lazyParams.first = event.first;
        this.state.lazyParams.rows = event.rows;
        this.state.lazyParams.page = event.page + 1;
        this.setState({
                
                lazyParams: this.state.lazyParams
            });
            this.getMembers();
        // alert();
    }

    DeleteUser = () => {
        confirmDialog({
            message: 'Are you sure you want to delete this user?',
            header: 'Delete User',
            icon: 'pi pi-trash',
            accept: ()=>{ 
                alert();
             },
        });
    };



    fieldNameTemplate = (rowData) => {
        let avatar = <Avatar label={rowData.first_name.charAt(0)+rowData.last_name.charAt(0)} className="mr-2" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />;
        if(rowData.member_picture){
            avatar = <Avatar image={`${serverUrl}${rowData.member_picture}`} className="mr-2" shape="circle" />;
        }

        return <span  className="user-full-name" tooltip="Enter your username" > 
                {avatar}
                {rowData.last_name} {rowData.first_name} {rowData.middle_initial} 
            </span>;
    }
    PrcNumberTemplate = (rowData) => {
        return <span>{rowData.prc_number.padStart(7,'0')} </span>;
    }
    TinNumberTemplate = (rowData) => {
        return <span>{rowData.prc_number.padStart(7,'0')} </span>;
    }
    ValidDate = (rowData) => {
        return <span>{moment(rowData.expiration).format('MMM DD, YYYY')} </span>;
    }
    checkStatus = (rowData) => {
        if(Number(rowData.active)<=0){
            return <span className="badge-forverify">For Verification </span>;
        }else{
            return <span  className="badge-approved">Approved </span>;
        }
        
    }
    ActionTemplate = (rowData) => {
        return <Button icon="pi pi-bars" onClick={(event) => this.openAction(rowData, event)} className="p-button-raised p-button-rounded p-button-sm p-button-outlined m-auto" />;
    }
    openAction = (data, e) => {
        this.setForm(data);
        this.menu.toggle(e)
    }
    changeTab(data){
        this.setState({ activeTab: data })
        if(data==2){
            this.setState( {payment_loader: true});
    
            // this.callPayments();
        }
    }

    callPayments = () => {

        // let ins = this;


        // this.paymentservice.getMemberPayment(this.state.form.user_id).then(function (response) {
        //     // console.log(response);
        //     let data = response.data.data;
        //     // console.log(data);
        //     ins.setState( {
        //         payment_called: true,
        //         payment_loader: false,
        //         payment_history: data});
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //     ins.setState( {payment_loader: false});
        //   });
    }

    onHide = (name) => {

        this.setState({show_form: false});
    }
    saveUser = (name) => {
        
        // console.log(this.new_data);

        let obj = this;

        
        let email = this.new_data.email;
        if(Object.entries(this.new_data).length === 0){
            this.toast.show({severity:'warn', summary: 'Warn Message', detail:'You dont have any changes.', life: 3000});
        }else{
            obj.setState({dialog_loader: true});
            this.memberservice.updateUser(this.new_data).then(function (response) {
                if(response.data.status_code==200){
                    console.log(response.data.data);
                    response.data.data.email = email;
                    obj.setForm(response.data.data);
                    obj.setState({show_form: false});

                    console.log(obj.state.members);
                    let user_index = GlobalService.getDataIndex(obj.state.members,'id', response.data.data.id);

                    console.log(user_index);
                    if(user_index!=undefined){
                        obj.state.members[user_index] = response.data.data;
                        obj.setState({members: obj.state.members});
                    }
                    
                    obj.setState({dialog_loader: false});
                    obj.toast.show({severity:'success', summary: 'Success Message', detail:'Member details seccessfully updated.', life: 3000});
                }

              })
              .catch(function (error) {
                console.log(error);
                
                this.toast.show({severity:'danger', summary: 'Warn Message', detail:'Something went wrong.', life: 3000});
                obj.setState({dialog_loader: false});
              });;
        }

    }

    renderHeader = (name)=> {
        return (
            <div>
                <i className="pi pi-user"></i>
                Profile
            </div>
        );
    }

    renderFooter = (name)=> {
        return (
            <div>
                <Button label="Save" icon="pi pi-check" disabled={this.state.activeTab!=0} onClick={() => this.saveUser(name)} autoFocus className="p-button-sm" />
                <Button label="Close" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text p-button-sm" />
            </div>
        );
    }

    newUserData = (langValue) => {
        this.new_data = langValue;
    }

    FormContent(){
        return (
            <div className="row">
                <div className="form-group col-4">
                    <label>First Name</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.first_name} onChange={(e) => this.setState({ form:{ first_name: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>Middle Initial</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.middle_initial} onChange={(e) => this.setState({ form:{ middle_initial: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>Last Name</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.last_name} onChange={(e) => this.setState({ form:{ last_name: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>PRC Number</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.prc_number} onChange={(e) => this.setState({ form:{ prc_number: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>TIN Number</label>
                    <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.tin_number} onChange={(e) => this.setState({ form:{ tin_number: e.value}  })}/>
                </div>
                <div className="form-group col-4">
                    <label>Expiration</label>
                    {/* <InputText type="text" className="p-inputtext-sm block mb-1 col-12" value={this.state.form.prc_number}/> */}
                    
                    <Calendar className="p-inputtext-sm block mb-1 col-12 p-0" value={this.state.form.expiration} onChange={(e) => this.setState({ form:{ expiration: e.value}  })} />
                </div>
                <div className="form-group col-6">
                    <label>Office Address</label>
                    <InputTextarea rows={3} className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.office_address}  onChange={(e) => this.setState({ form:{ office_address: e.value}  })}/>
                    {/* onChange={(e) => setValue(event.target.value)} */}
                </div>
                <div className="form-group col-6">
                    <label>Home Address</label>
                    <InputTextarea rows={3} className="p-inputtext-sm block mb-1 col-12"  value={this.state.form.home_address}  onChange={(e) => this.setState({ form:{ home_address: e.value}  })}/>
                </div>
            </div>
        );

    }

    render() {
        

        const template = {
            layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            },
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    </React.Fragment>
                );
            },
        };
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={this.getMembers} />;
        const paginatorRight = <Button type="button" icon="pi pi-plus" className="p-button-text" />;

        const loader = <div className="dialog-loader"><i className="fa fa-spin fa-spinner"></i> Loading...</div>;

        return (

            
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Dialog 
                    visible={this.state.show_form} 
                    onHide={this.onHide} 
                    breakpoints={{'960px': '75vw', '640px': '100vw'}} 
                    style={{width: '80vw'}} 
                    draggable={false} 
                    resizable={false}
                    footer={this.renderFooter('displayBasic2')}
                    header="Member Profile"
                >
                    <TabMenu model={this.items_dialog} activeIndex={this.state.activeTab} onTabChange={(e) => this.changeTab(e.index)} />  
                    <div className="dialog-container">
                        {(this.state.dialog_loader) && loader} 
                        {(this.state.activeTab==0) && <UserDetails userData={this.state.form} changeData={this.newUserData}/>}
                        {(this.state.activeTab==1) && <UserLodgement userData={this.state.form}/>}
                        {(this.state.activeTab==2) && <PaymentHistory userData={this.state.form}  paymentList={this.state.payment_history} payment_loader={this.state.payment_loader}/>}
                    </div>

                </Dialog>
                

                {/* <ConfirmDialog /> */}
                <TieredMenu model={this.items} popup ref={el => this.menu = el} id="overlay_tmenu" />
                <div className="head-label">
                    <h5 className="float-left"> <i className="pi pi-fw pi-users"></i> Profile Management</h5>


                    <InputText type="text" className="search_text" placeholder="Search here..."  value={this.state.lazyParams.search_text} onChange={(e) => this.searchText(e)}/>
                     
                </div>
                <div className="card">
                    <DataTable value={this.state.members} 
                        size="small" 
                        lazy 
                        responsiveLayout="scroll" 
                        totalRecords={this.state.totalRecords}
                        onPage={this.onPage}
                        onSort={this.onSort} 
                        paginator 
                        first={this.state.lazyParams.first} 
                        rows={this.state.lazyParams.rows} 
                        paginatorTemplate={template}
                        paginatorLeft={paginatorLeft} 
                        paginatorRight={paginatorRight}
                        scrollable 
                        scrollHeight={this.table_size}
                        paginatorClassName="justify-content-end" className="mt-6"
                        loading={this.state.loading}
                        sortField={this.state.lazyParams.sortField} 
                        sortOrder={this.state.lazyParams.sortOrder}
                        showGridlines
                        

                    >
                        <Column sortable field="id" header="ID" style={{width:'50px'}}  className="small-width" ></Column>
                        <Column sortable field="last_name,first_name" header="Name" style={{width:'150px'}} body={this.fieldNameTemplate}></Column>
                        <Column sortable field="email"  header="Email" style={{width:'150px'}}></Column>
                        <Column sortable field="prc_number"  header="PRC Number" style={{width:'150px'}} body={this.PrcNumberTemplate}></Column>
                        <Column sortable field="expiration"  header="Valid Until" style={{width:'150px'}} body={this.ValidDate}></Column>
                        {/* <Column sortable field="tin_number"  header="TIN Number" style={{width:'150px'}}></Column> */}
                        <Column sortable field="status"  header="Status" style={{width:'150px'}} body={this.checkStatus}></Column>
                        {/* <Column sortable field="office_address"  header="Office Address" style={{width:'300px'}}></Column> */}
                        {/* <Column sortable field="home_address"  header="Home Address" style={{width:'300px'}}></Column> */}
                        <Column
                            field="category"
                            header="Action"
                            frozen
                            className="small-width text-center"
                            body={this.ActionTemplate}
                            alignFrozen="right"
                        ></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}