import { getToken, clearLogin } from '../Utils/Common';


class Auth{
    constructor(){
        this.authenticated = false;
    }
    login(cb){
        this.authenticated = true;
        cb()
    }
    logout(cb){
        this.authenticated = false;
        clearLogin();
        cb()
    }
    isAuthenticated(){
        if(getToken()) return true;
        return false;
    }

}

export default new Auth()