class GlobalService {
    static classInstance = null;
    static getInstance() {
        if (GlobalService.classInstance === null) {
            GlobalService.classInstance = new GlobalService();
        }
        return this.classInstance;
    }

    constructor() {
        this.data = 1;
      }


    hello(){
        alert(this.data);
    }
    changeData(new_data){
        this.data = new_data;
    }

    getData(itemdata=[], key='',value='') {
        var ret;
        itemdata.forEach(function(item){
            if(item[key] === value){
                ret = item;
            }
        });
    
        return ret;
    };
    getDataIndex(itemdata=[], key='',value='') {
      var ret;
      itemdata.forEach(function(item, i){
          if(item[key] === value){
            // console.log('test',i);
              ret = i;
          }
      });
    
      return ret;
    };

}

  


export default GlobalService.getInstance();