import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Main from './Pages/Main/Main';
import Login from './Pages/Login/Login';



import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import './App.scss';
import { ProtectedRout } from './Auth/protected.route';

 
function App() {
  let header = (
    <div>
    </div>

  );
  let footer = (
    <div>
      
    </div>

  );

  let routes = (
    <Switch>
        <ProtectedRout exact path="/" component={Main} />
        <ProtectedRout exact path="/hello" component={Main} />
        <ProtectedRout exact path="/members" component={Main} />
        <ProtectedRout exact path="/members/*" component={Main} />
        <ProtectedRout exact path="/payment" component={Main} />
        <ProtectedRout exact path="/payment/*" component={Main} />
        <ProtectedRout exact path="/certificates" component={Main} />
        <ProtectedRout exact path="/certificates/*" component={Main} />
        <ProtectedRout exact path="/events_and_activities" component={Main} />
        <ProtectedRout exact path="/info_pages" component={Main} />

        <Route path="/login" component={Login} />
        <Route path="*" component={ ()=> "404 NOT FOUND"} />
    </Switch>
  );




  return (
    <div className="App">

      <BrowserRouter>
        <div>
          

        {/* <nav class="navbar navbar-light bg-light">

          <img src="/assets/img/ccbi-full-logo.png" alt="Chamber of Customs Brokers, Inc." class="img-main-logo"/>
          <NavLink exact activeClassName="active" to="/">Home</NavLink>
          <NavLink exact activeClassName="active" to="/about">About</NavLink>
          <NavLink activeClassName="active" to="/login">Login</NavLink>
          <NavLink activeClassName="active" to="/dashboard">Dashboard</NavLink>
        </nav> */}
          <div className="content">
              {routes}
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}
 
export default App;