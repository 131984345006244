
import {axiosInstance} from '../../Auth/interceptor';
import axios from 'axios'
import { apiUrl } from '../../Utils/Common';


class ActivitiesService {
    static classInstance = null;
    static getInstance() {
        if (ActivitiesService.classInstance === null) {
            ActivitiesService.classInstance = new ActivitiesService();
        }
        return this.classInstance;
    }

    default_tab = 0;
    constructor() {
    
    }
    setTab(tab){
        this.default_tab = tab;
    }
    getTab(){
       return this.default_tab;
    }


    getEvents(params){

        return axios.post('Events/list/',params)
    }
    getJoiners(id){
        return axios.get('Events/joiners/'+id)
    }

    updateUser(data){
        return axios.put('Events/event', data)
      }
      
    addEvent(data){
        return axios.post('Events/event', data)
      }
    updateEvent(data){
        return axios.put('Events/event', data)
      }
    delete(data){
        return axios.delete('Events/event/'+data)
      }

}

  


export default ActivitiesService.getInstance();

